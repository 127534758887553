<template>
	<div class="main">
         <TheTopbar :pendingFile="pendingOnly"/>
         <slot />
	<div class="main-container">
    <div class="d-flex">
      <div class="content-wrapper">
        
          <div class="d-flex justify-content-between modal-header m-3"> 
                <h5 class="text-white">MANAGE DOCUMENTS</h5>
               
          </div>
          <div class="options d-flex">
                  <div> 
                    <button class="btn btn-main "  :class="{ active: state == 'SUBMITTED'}" @click="changeState('SUBMITTED')" >PENDING <b-badge class="text-dark">{{pendingOnly.length}}</b-badge></button>
                    <button class="btn btn-main"  :class="{ active: state == 'APPROVED'}" @click="changeState('APPROVED')">APPROVED <b-badge class="text-dark">{{approvedOnly.length}}</b-badge></button>
                    <button class="btn btn-main"  :class="{ active: state == 'REJECT'}" @click="changeState('REJECT')">REJECTED <b-badge class="text-dark">{{rejectOnly.length}}</b-badge></button>
                  </div>
                  <div > 
                    
                  </div>
          </div>
          <!-- <b-input-group prepend="Enter Customer ID, name, mobile number or email address" class="mt-3" >
                    <b-form-input v-model="searchCustomer" @keyup.enter="searchByNamePhoneEmail()"></b-form-input>
                    <b-input-group-append>
                   
                    </b-input-group-append>
          </b-input-group> -->
        <div class="modal-body">
              <div class="market-content">
                
                <div class="market-content">
                
                  <div class="text-white" v-if="files.length == 0">
                      No files found
                  </div>
                  <button style="float: right !important;" @click="exportToCsv">save as .csv</button>
                  <table class="table">
                    <thead>
                      <tr>
                          <th>Entity ID</th>
                          <th>Company  <button @click="sortCompanies" class="btn btn-primary mb-3">
                         {{ sortAscending ? 'Z-A' :'A-Z' }}
                           </button></th>
                          <th>Date <button @click="clearSort" class="btn btn-primary mb-3">
                            {{ sortDescending ? 'Oldest' :'Latest' }}
                          </button></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          
                      </tr>
                    </thead>
                  
                      <tbody v-for="item in paginatedDataPending" :key="item.id" v-if="state == 'SUBMITTED'">
                      <td><button class="btn btn-info" v-b-tooltip.hover title="Click to view and update Customer's profile"><a :href="'/#/customer-application-list/' + item.entityId">{{ item.entityId }}</a></button></td>
                     <td>{{ item.companyName }}</td>
                      <td>{{ formatDate(item.createDate) }}</td>
                      <td>{{ item.businessfileId }}</td>
                      <td>{{ item.fileType }}</td>
                      <td v-if="item.fileType != item.description">{{ item.description }}</td>
                      <td v-else>-</td>
                      <td>{{ item.status }}</td>
                      <td><button @click="viewUpload(item)" class="btn btn-light" v-b-tooltip.hover title="Click to view and action uploaded documents">view</button></td>
                    </tbody>
                
                    
                    <tbody v-for="item in paginatedDataApproved" :key="item.id" v-if="state == 'APPROVED'">
                      <td><button class="btn btn-info" v-b-tooltip.hover title="Click to view and update Customer's profile"><a :href="'/#/customer-application-list/' + item.entityId">{{ item.entityId }}</a></button></td>
                      <td>{{ item.companyName }}</td>
                      <td>{{ formatDate(item.createDate )}}</td>
                      <td>{{ item.businessfileId }}</td>
                      <td>{{ item.fileType }}</td>
                      <td v-if="item.fileType != item.description">{{ item.description }}</td>
                      <td v-else>-</td>
                      <td>{{ item.status }} at {{ formatDate(item.updateDate) }}</td>
                      <td><button @click="viewUpload(item)" class="btn btn-light" v-b-tooltip.hover title="Click to view and action uploaded documents">view</button></td>
                    </tbody>
                    <tbody v-for="item in paginatedDataRejected" :key="item.id" v-if="state == 'REJECT'">
                      <td><button class="btn btn-info" v-b-tooltip.hover title="Click to view and update Customer's profile"><a :href="'/#/customer-application-list/' + item.entityId">{{ item.entityId }}</a></button></td>
                      <td>{{ item.companyName }}</td>
                      <td>{{ formatDate(item.createDate) }}</td>
                      <td>{{ item.businessfileId }}</td>
                      <td>{{ item.fileType }}</td>
                      <td v-if="item.fileType != item.description">{{ item.description }}</td>
                      <td v-else>-</td>
                      <td>{{ item.status }} at {{ formatDate(item.updateDate) }} due to {{ item.remarks }}</td>
                      <td><button @click="viewUpload(item)" class="btn btn-light" v-b-tooltip.hover title="Click to view and action uploaded documents">view</button></td>
                    </tbody>
                    <tbody v-if="fileDetails.length == 0">
                      <td><button class="btn btn-info" v-b-tooltip.hover title="Click to view and update Customer's profile"><a :href="'/#/customer-application-list/' + fileDetails.entityId">{{ fileDetails.entityId }}</a></button></td>
                      <td>{{ fileDetails.companyName }}</td>
                      <td>{{ formatDate(fileDetails.createDate) }}</td>
                      <td>{{ fileDetails.businessfileId }}</td>
                      <td>{{ item.fileType }}</td>
                      <td>{{ fileDetails.description }}</td>
                      <td>{{ fileDetails.status }}</td>
                      <td ><button @click="viewUpload(fileDetails)" class="btn btn-light" v-b-tooltip.hover title="Click to view and action uploaded documents">view</button></td>
                    </tbody>
                  </table>
                  </div>
                 
                  </div>
                  <div v-if="state == 'SUBMITTED'">
                    <button @click="prevPagePending" :disabled="currentPagePending === 1" class="btn btn-light">Previous</button>
                    <span class="px-2 text-white"> {{ currentPagePending }} </span>
                    <button @click="nextPagePending" :disabled="currentPagePending === totalPagesPending" class="btn btn-light">Next</button>
                  </div>
                  <div v-if="state == 'APPROVED'">
                    <button @click="prevPageApproved" :disabled="currentPageApproved === 1" class="btn btn-light">Previous</button>
                    <span class="px-2 text-white"> {{ currentPageApproved }} </span>
                    <button @click="nextPageApproved" :disabled="currentPageApproved === totalPagesApproved" class="btn btn-light">Next</button>
                  </div>
                  <div v-if="state == 'REJECT'">
                    <button @click="prevPageRejected" :disabled="currentPageRejected === 1" class="btn btn-light">Previous</button>
                    <span class="px-2 text-white"> {{ currentPageRejected }} </span>
                    <button @click="nextPageRejected" :disabled="currentPageRejected === totalPagesRejected" class="btn btn-light">Next</button>
                  </div>
                  
        </div>
        
        <hr>
       
      </div>
    </div>
    </div>
    <ViewUpload v-if="showUpload" @close="viewUpload(data)" :data="dataInfo"></ViewUpload>
	</div>
  
 
          
</template>
         
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Approve from '../components/modal/Approve.vue'
  import Header from '../components/Header.vue'
  import ViewUpload from '../components/modal/Documents/ViewUpload.vue'
 

  export default {
      name: 'TransactionSearch',
      components: {
        TheTopbar,
        Approve,
        Header,
        ViewUpload
         
      },
      
       data: function() {
        const today = new Date();
        return { 
          searchCustomer:"",
          showUpload: false,
          businessfileId:'',
          pendingFile: "",
          showChangePin: false,
          showLogoutModal: false,
          showInputComment: false,
          fromDate: today,
          toDate: today,
          businessfileIdDetails:{},
          fileDetails:{},
          item:{},
          files: [],
          dataInfo:[],
          statusValue:'',
          state:'SUBMITTED',
          status: [
              {text: 'Select Status', value: null}, 
              {text: 'SUCCESS', value: '11'}, 
              {text: 'PENDING', value: '80'}, 
              {text: 'CANCELLED', value: '00'}, 
              {text: 'FAILED', value: '90'}
            ],
          itemsPerPage: 20,
          currentPagePending: 1,
          currentPageApproved:1,
          currentPageRejected:1,
          sortAscending: true,
          sortDescending: true
        }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        pendingOnly(){
          return this.files.filter(item => item.status === 'SUBMITTED')
          
        },
        approvedOnly(){
          return this.files.filter(item => item.status === 'ACCEPTED')
        },
        rejectOnly(){
          return this.files.filter(item => item.status === 'REJECT')
        },
        totalPagesPending() {
          return Math.ceil(this.pendingOnly.length / this.itemsPerPage);
        },
        paginatedDataPending() {
          const start = (this.currentPagePending - 1) * this.itemsPerPage;
          const end = start + this.itemsPerPage;
          return this.pendingOnly.slice(start, end);
          
        },
        totalPagesApproved() {
          return Math.ceil(this.approvedOnly.length / this.itemsPerPage);
        },
        paginatedDataApproved() {
          const start = (this.currentPageApproved - 1) * this.itemsPerPage;
          const end = start + this.itemsPerPage;
          return this.approvedOnly.slice(start, end);
          
        },
        totalPagesRejected() {
          return Math.ceil(this.rejectOnly.length / this.itemsPerPage);
        },
        paginatedDataRejected() {
          const start = (this.currentPageRejected - 1) * this.itemsPerPage;
          const end = start + this.itemsPerPage;
          return this.rejectOnly.slice(start, end);
          
        },
        
      },
     
      methods:{
        searchByNamePhoneEmail(){
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: 'ax/customerSearchByEmailOrNameOrPhone?emailAddress=' + this.searchCustomer,
                   
                    };

				this.axios(options)
				.then((response) => { 
                    this.isLoading = false
                    this.init.list.customers = response.data
                    console.log(this.init.list.customers)
                    this.state = 'search'
                })
        },
        
         searchFor(){
             const params = {
		          eids: this.entityId,
		        }
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    params,
                    url: '/ax/getCustomerProfileList',
                   
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                })
            },
        initSearch(){
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/initSearch',
               
                    };

				this.axios(options)
				.then((response) => { 
                   this.customers = response.data
                   this.state = 'search'
                   
                })
        },
        clearSort() {
          this.paginatedDataPending.sort((a, b) => {
            return this.sortDescending
              ? a.createDate.localeCompare(b.createDate)
              : b.createDate.localeCompare(a.createDate);
          });
          this.paginatedDataApproved.sort((a, b) => {
            return this.sortDescending
              ? a.createDate.localeCompare(b.createDate)
              : b.createDate.localeCompare(a.createDate);
          });
          this.paginatedDataRejected.sort((a, b) => {
            return this.sortDescending
              ? a.createDate.localeCompare(b.createDate)
              : b.createDate.localeCompare(a.createDate);
          });
          this.sortDescending = !this.sortDescending; // Toggle sort direction
          // this.paginatedDataPending.sort((a, b) => a.businessfileId - b.businessfileId);
          // window.location.reload();
        },
        sortCompanies() {
          this.paginatedDataPending.sort((a, b) => {
            return this.sortAscending
              ? a.companyName.localeCompare(b.companyName)
              : b.companyName.localeCompare(a.companyName);
          });
          this.paginatedDataApproved.sort((a, b) => {
            return this.sortAscending
              ? a.companyName.localeCompare(b.companyName)
              : b.companyName.localeCompare(a.companyName);
          });
          this.paginatedDataRejected.sort((a, b) => {
            return this.sortAscending
              ? a.companyName.localeCompare(b.companyName)
              : b.companyName.localeCompare(a.companyName);
          });
          this.sortAscending = !this.sortAscending; // Toggle sort direction
        },
       
        nextPagePending() {
          if (this.currentPagePending < this.totalPagesPending) {
            this.currentPagePending++;
          }
        },
        prevPagePending() {
          if (this.currentPagePending > 1) {
            this.currentPagePending--;
          }
        },
        nextPageApproved() {
          if (this.currentPageApproved < this.totalPagesApproved) {
            this.currentPageApproved++;
          }
        },
        prevPageApproved() {
          if (this.currentPageApproved > 1) {
            this.currentPageApproved--;
          }
        },
        nextPageRejected() {
          if (this.currentPageRejected < this.totalPagesRejected) {
            this.currentPageRejected++;
          }
        },
        prevPageRejected() {
          if (this.currentPageRejected > 1) {
            this.currentPageRejected--;
          }
        },
        goHome(){
          window.location.href="/#/customer-search";
        },
        goBack(){
          this.$router.go(-1);
        },
        sortNames() {
         
        },
        viewUpload(docData){
          this.dataInfo = docData
          this.showUpload = !this.showUpload
          console.log(docData)
        },
        changeState(state) {
            this.state = state;
            localStorage.setItem("state", state);
          },
        getState() {
            let state = localStorage.getItem("state")
            if(state != "" || state != undefined) {
            this.state = state
            }
        },
        formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
          
         
        getAllFiles(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                url: '/ax/customer/businessfile/all'
                          };
                      this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                          this.files = response.data

                      
                          }).catch((err) => { 
                      })
          },

          exportToCsv() {
                    this.getAllFiles();
                      let filename = "pending-documents" + "-" + moment(String(new Date)).format('MM_DD_YYYY_HH:mm:ss') +  ".csv";
                        
                        let csv = []; 
                        let header1 = 'Entity ID, Company Name, Submitted Date, Description, Update Date, Status';
                        csv.push(header1);

                    this.files.forEach((el, i) => {
                        this.csvData = el
                        let row1 = this.csvData.entityId + "," + this.csvData.companyName + "," + this.csvData.createDate + ","   + this.csvData.description + "," + this.csvData.updateDate + "," + this.csvData.status
                        csv.push(row1);

                    })
                    
                   
               
                    this.downloadCsv(csv.join("\n"), filename);
                
                

            },
            downloadCsv(csv,filename) {
                let csvFile;
                let downloadLink;
                csvFile = new Blob([csv], {type: "text/csv"});
                downloadLink = document.createElement("a");
                downloadLink.download = filename;
                downloadLink.href = window.URL.createObjectURL(csvFile);
                // downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);

                downloadLink.click();
            },
          searchById(){
            const params = {
              txnId: this.businessfileId
                      
                    }
                    const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/txnIdSearch'
                          };
                      this.axios(options)
                          .then((response) => { 
                            this.txnIdDetails = response.data
                            console.log(this.txnIdDetails)

                            this.txnIdDetails.forEach((el, i) => {
                              this.txnDetails = el

                             
                            })
                            
                          }).catch((err) => { 
                      })
          },
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
             this.axios(options)
              .then((response) => {
              
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getAllFiles();
          this.getState();
      }
  }
</script>
        
<style lang=scss scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
 .market-content {
  width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 450px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    width: 100%;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>